import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';

export const query = graphql`
  query Profile {
    file(relativePath: { eq: "profile.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const AboutPage = ({ data }: any) => {
  return (
    <Layout>
      <div className="container about-page px-5">
        <div className="row mt-5 rounded-3 shadow-box mb-3 p-4">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-between align-items-start">
            <h1 className="titleEffect">Personal</h1>
            <h1 className="titleEffect fw-bold">Social Card</h1>
            <p className="mt-4 mb-1 titleEffect">
              It would make my day to hear from you.
            </p>
            <p className="titleEffect fw-bold">
              Yo can reach out by sending a nostalgic email or a simple message
            </p>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <i
                  className="bi bi-envelope-fill fs-4 nav-link active"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-email"
                  role="tab"
                  aria-controls="pills-email"
                  aria-selected="true"
                ></i>
              </li>
              <li className="nav-item" role="presentation">
                <i
                  className="bi bi-facebook fs-4 nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-facebook"
                  role="tab"
                  aria-controls="pills-facebook"
                  aria-selected="true"
                ></i>
              </li>
              <li className="nav-item" role="presentation">
                <i
                  className="bi bi-reddit fs-4 nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-reddit"
                  role="tab"
                  aria-controls="pills-reddit"
                  aria-selected="true"
                ></i>
              </li>
              <li className="nav-item" role="presentation">
                <i
                  className="bi bi-discord fs-4 nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-discord"
                  role="tab"
                  aria-controls="pills-discord"
                  aria-selected="true"
                ></i>
              </li>
              <li className="nav-item" role="presentation">
                <i
                  className="bi bi-github fs-4 nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-github"
                  role="tab"
                  aria-controls="pills-github"
                  aria-selected="true"
                ></i>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-email"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                carloshdz.udb@gmail.com
              </div>
              <div
                className="tab-pane fade"
                id="pills-facebook"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                https://www.facebook.com/dvlopr
              </div>
              <div
                className="tab-pane fade"
                id="pills-reddit"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="pills-discord"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                Warloss#3009
              </div>
              <div
                className="tab-pane fade"
                id="pills-github"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                https://github.com/CarlosHdz7
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="text-center">
              <Img
                fluid={data.file.childImageSharp.fluid}
                className="img-fluid img-profile mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
